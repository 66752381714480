<template>
  <div class="sample">
    <div style="margin-top: 30px; margin-bottom: 8px; font-size: 14px">
      共有{{ pagination.total }}条数据
    </div>
    <a-table
      style="background-color: #fff"
      rowKey="id"
      bordered
      :columns="columns"
      :data-source="data"
      :pagination="pagination"
      @change="handleTableChange"
    >
    </a-table>
  </div>
</template>

<script>
import { listSample } from "@/api/sample";

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "样本名称",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "检测时间",
    dataIndex: "create_time",
    key: "create_time",
  },
];
const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 18 },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 18, offset: 4 },
};
export default {
  name: "Subject",
  data() {
    return {
      data: [],
      columns,
      showModal: false,
      pagination: {
        current: 1,
        total: 0,
        pageSize: 10,
      },
      formItemLayout,
      formTailLayout,
      form: this.$form.createForm(this, { name: "dynamic_rule" }),
    };
  },
  methods: {
    handleTableChange(pagination) {
      this.pagination.current = pagination.current;
      this.loadData(this.pagination);
    },

    loadData() {
      listSample(this.pagination.current, this.pagination.pageSize).then(
        (res) => {
          this.pagination.total = res.data.total;
          this.data = res.data.rows || [];
        }
      );
    },
  },

  mounted() {
    this.loadData();
  },
};
</script>

<style scoped>
</style>